import React from "react";
import gsap from "gsap";

window.onload = () => {
    gsap.to(".bg-img", {opacity:1})
}

const Background = ({ use_css }) => {

    if (use_css !== undefined) {
        return (
            <div id="bg-container" className={use_css}>
                <div>
                    <img id="bg-img" alt="" className="bg-img absolute stylesheet-img opacity-0"></img>
                    <div className="absolute w-screen stylesheet-bg  flex items-center"></div>
                </div>
            </div>
        )
    } else {
        return (
            <div id="bg-container" className="absolute bg-black stylesheet-h w-full overflow-hidden ">
                <div>
                    <img id="bg-img" alt="" className="bg-img absolute h-screen w-full stylesheet-img stylesheet-h overflow-hidden opacity-0"></img>
                    <div className="absolute w-screen h-screen stylesheet-bg stylesheet-h flex items-center"></div>
                </div>
            </div>
        )
    }
}

export default Background;
