import React from "react";
import Navbar from "../components/navbar";
import Background from "../components/background";
import gsap from "gsap";

const Kontakt = () => {
  setTimeout(() => {
    gsap.to(".fade-in", { opacity: 1 });
  }, 400);
  setTimeout(() => {
    gsap.fromTo(".l-txt-1", { opacity: 0, y: 15 }, { opacity: 1, y: 0 });
  }, 500);
  setTimeout(() => {
    gsap.fromTo(".l-txt-2", { opacity: 0, y: 15 }, { opacity: 1, y: 0 });
  }, 600);
  setTimeout(() => {
    gsap.fromTo(".l-txt-3", { opacity: 0, y: 15 }, { opacity: 1, y: 0 });
  }, 700);
  setTimeout(() => {
    gsap.fromTo(".l-txt-4", { opacity: 0, y: 15 }, { opacity: 1, y: 0 });
  }, 800);
  
  return (
    <div className="overflow-hidden h-screen">
      <Navbar />
      <Background />
      <div className="absolute w-full h-full flex justify-center items-center">
        <div className="stylesheet-bg-profile flex flex-col items-center">
          <div className="opacity-0 fade-in gsap-btn-anim cursor-pointer mb-4" onClick={() => window.location.href = "/"}>
            <img alt="" className="h-8 w-8" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA1klEQVR4nO3Yu0rEUBCA4UHfaktRWbXTVWy0VPGhtXEbwUIWL+Dlk1OJSxYExTiH+Z4gP0kmcxJRSimllFLKX8EMt7jHSWSEY7z61GLWIxMc4sVXd1iLLHAwENHuzFFkgV08L0W84TSywM5AxDvOIgtM8TQQcR5ZYHtFxEVkga0VEZeRBTbxmD1iAw/Gc92Gy08jJlgY302F/KNH66qN/PgNXbzsXY3frj6IXa0oXS2NXa3x3zhYzSIbw0fdeXTy82EeWWG/BbS9CHtjX08ppZRSSimx7AOxXwwKTXfxtgAAAABJRU5ErkJggg==" />
          </div>
          <div className="pl-16 pr-16 mb-5 text-center">
              <h1 className=" l-txt-3 opacity-0 font-title text-3xl pb-2 text-white border-b">Kontaktandmed</h1>
            </div>
          <div className="w-full flex flex-col items-center mt-10">
            <div className="pl-16 pr-16 mb-8 text-center">
              <h1 className="l-txt-1 opacity-0 font-title text-3xl pb-2 text-white border-b">Telefon</h1>
              <p className="l-txt-2 opacity-0 pr-0 text-white font-sec pt-3">+372 5687 xxxx</p>
            </div>
            <div className="pl-16 pr-16 mb-8 text-center">
              <h1 className="l-txt-3 opacity-0 font-title text-3xl pb-2 text-white border-b">E-post</h1>
              <p className="l-txt-4 opacity-0 pr-0 text-white font-sec pt-3">hugo.vaarmaa@voco.ee</p>
            </div>
            <div className="pl-16 pr-16 mb-8 text-center">
              <h1 className="l-txt-3 opacity-0 font-title text-3xl pb-2 text-white border-b">Aadress</h1>
              <p className="l-txt-4 opacity-0 pr-0 text-white font-sec pt-3">Võõrastele ei tohi öelda!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Kontakt;
