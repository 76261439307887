import React, { useState } from 'react';
import Navbar from '../components/navbar';
import Masonry from 'react-masonry-css';

const Browse = () => {
  const [enlargedUrl, setEnlargedUrl] = useState(null);

  // Define paths for lower-quality preview images and higher-quality full-size images
  const previewImageUrls = [
    process.env.PUBLIC_URL + '/d/pilt1d.jpg',
    process.env.PUBLIC_URL + '/d/pilt2d.jpg',
    process.env.PUBLIC_URL + '/d/pilt3d.jpg',
    process.env.PUBLIC_URL + '/d/pilt4d.jpg',
    process.env.PUBLIC_URL + '/d/pilt5d.jpg',
    process.env.PUBLIC_URL + '/d/pilt6d.jpg',
    process.env.PUBLIC_URL + '/d/pilt7d.jpg',
    process.env.PUBLIC_URL + '/d/pilt8d.jpg',
    process.env.PUBLIC_URL + '/d/pilt9d.jpg',
    process.env.PUBLIC_URL + '/d/pilt10d.jpg',
    process.env.PUBLIC_URL + '/d/pilt11d.jpg',
    process.env.PUBLIC_URL + '/d/pilt12d.jpg',
    process.env.PUBLIC_URL + '/d/pilt13d.jpg',
    process.env.PUBLIC_URL + '/d/pilt14d.jpg',
    process.env.PUBLIC_URL + '/d/pilt15d.jpg',
    process.env.PUBLIC_URL + '/d/pilt16d.jpg',
    process.env.PUBLIC_URL + '/d/pilt17d.jpg',


    // Add paths for other preview images
  ];

  const fullSizeImageUrls = [
    process.env.PUBLIC_URL + '/o/pilt1.jpg',
    process.env.PUBLIC_URL + '/o/pilt2.jpg',
    process.env.PUBLIC_URL + '/o/pilt3.jpg',
    process.env.PUBLIC_URL + '/o/pilt4.jpg',
    process.env.PUBLIC_URL + '/o/pilt5.jpg',
    process.env.PUBLIC_URL + '/o/pilt6.jpg',
    process.env.PUBLIC_URL + '/o/pilt7.jpg',
    process.env.PUBLIC_URL + '/o/pilt8.jpg',
    process.env.PUBLIC_URL + '/o/pilt9.jpg',
    process.env.PUBLIC_URL + '/o/pilt10.jpg',
    process.env.PUBLIC_URL + '/o/pilt11.jpg',
    process.env.PUBLIC_URL + '/o/pilt12.jpg',
    process.env.PUBLIC_URL + '/o/pilt13.jpg',
    process.env.PUBLIC_URL + '/o/pilt14.jpg',
    process.env.PUBLIC_URL + '/o/pilt15.jpg',
    process.env.PUBLIC_URL + '/o/pilt16.jpg',
    process.env.PUBLIC_URL + '/o/pilt17.jpg',
    // Add paths for other full-size images
  ];

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1
  };

  const handlePreviewClick = (index) => {
    setEnlargedUrl(fullSizeImageUrls[index]);
  };

  const handleClose = () => {
    setEnlargedUrl(null);
  };

  return (
    <div className="bg-g-3">
      <Navbar />
      <div className="w-full h-full pt-2">
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {previewImageUrls.map((url, index) => (
            <div key={index} className="rounded-lg css-border overflow-hidden cursor-pointer" onClick={() => handlePreviewClick(index)}>
              <img src={url} alt={`Image ${index + 1}`} className="w-full h-full rounded-md" />
            </div>
          ))}
        </Masonry>
      </div>
      {enlargedUrl && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-75 z-50" onClick={handleClose}>
          <img src={enlargedUrl} alt="enlarged" className="max-w-full max-h-full" />
        </div>
      )}
    </div>
  );
};

export default Browse;
