import React from "react";
import Navbar from "../components/navbar";
import Background from "../components/background";
import gsap from "gsap";

const Opingud = () => {
  setTimeout(() => {
    gsap.to(".fade-in", { opacity: 1 });
  }, 400);
  setTimeout(() => {
    gsap.fromTo(".l-txt-1", { opacity: 0, y: 15 }, { opacity: 1, y: 0 });
  }, 500);
  setTimeout(() => {
    gsap.fromTo(".l-txt-2", { opacity: 0, y: 15 }, { opacity: 1, y: 0 });
  }, 600);
  setTimeout(() => {
    gsap.fromTo(".l-txt-3", { opacity: 0, y: 15 }, { opacity: 1, y: 0 });
  }, 700);
  setTimeout(() => {
    gsap.fromTo(".l-txt-4", { opacity: 0, y: 15 }, { opacity: 1, y: 0 });
  }, 800);

  const buttonAnimEnter = () => {
    gsap.to(".gsap-btn-anim", { paddingLeft: 128 });
  };
  const buttonAnimLeave = () => {
    gsap.to(".gsap-btn-anim", { paddingLeft: 96 });
  };
  
  return (
    <div className="overflow-hidden h-screen">
      <Navbar />
      <Background />
      <div className="absolute w-full stylesheet-h flex items-center justify-center">
        <div className="stylesheet-bg-profile flex w-full pt-16 pb-16 items-center relative">
          <div className="w-full absolute top-0 left-1/2 transform -translate-x-1/2 text-white z-10">
            <h1 className="text-center text-3xl">
              <span className="font-title" style={{ textDecoration: 'underline', marginBottom: '1px' }}>Õpingud</span>
            </h1>
          </div>
          <div className="flex w-full">
            <div className="w-1/3 pl-16">
              <h1 className="l-txt-1 opacity-0 font-title text-2xl pb-2 text-white border-b">IT valdkonna alusteadmised</h1>
              <p className="l-txt-2 opacity-0 pr-0 text-white font-sec pt-3">Oskan.</p>
            </div>
            <div className="w-1/3 pl-16">
              <h1 className="l-txt-3 opacity-0 font-title text-2xl  pb-2 text-white border-b">Programmeerimine</h1>
              <p className="l-txt-4 opacity-0 pr-0 text-white font-sec pt-3">Oskan programmeerimist umbes keskmise tasemel, isegi see lehekülg on kirjutatud nullist.</p>
            </div>
            <div className="w-1/3 pl-16 pr-16">
              <h1 className="l-txt-3 opacity-0 font-title text-2xl pb-2 text-white border-b">Timo Puistaja kirjeldus</h1>
              <p className="l-txt-4 opacity-0 pr-0 text-white font-sec pt-3">Tupsujumal, suurim hüppehirmutaja vocos ning peremees, kes cookib servereid katki ja kustutab meie personaalset informatsiooni vmides.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Opingud;
