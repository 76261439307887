import React, { useEffect } from "react";
import Navbar from "../components/navbar";
import Background from "../components/background";
import gsap from "gsap";


const Main = () => {
  useEffect(() => {
    setTimeout(() => {
      gsap.to(".fade-in", {opacity:1})
      gsap.fromTo(".name", {opacity:0, x:-15},{opacity:1, x:0})  
    }, 400);
    
  },[])
    return(
        <div class="overflow-hidden h-screen">
          <Navbar></Navbar>


          <Background></Background>
          <div class="absolute w-full stylesheet-h flex items-center justify-center">
          <div class="stylesheet-bg-profile flex w-full pt-16 pb-16 items-center">
              <div class="ml-48">
                <img alt="" src="pfp.JPG" class="fade-in opacity-0 rounded-full w-64 h-64 border-4"></img>
              </div>
              <div class="pl-16">
                <h1 class="text-white text-4xl font-title opacity-0 name">Hugo Väärmaa</h1>
                <p  class="text-white text-xl font-sec name opacity-0">Tere tulemast minu portfooliosse, millega ma kandideerin praktikale ja arendan ennast selle koostamisel.</p>
                <div class="flex">
                <button class="fade-in opacity-0 shadow-md stylesheet-bg-btn items-center rounded-md flex p-1 mt-1" onClick={() => window.location.href = "https://www.instagram.com/hugovaarmaa/"}>
                  <img alt="" class="h-6 w-6" src="inst.png"></img>
                </button>
                <div class="p-1">{}</div>
                <button class="fade-in opacity-0 shadow-md stylesheet-bg-btn items-center rounded-md flex p-1 mt-1" onClick={() => window.location.href = "https://et.wikipedia.org/wiki/Tartu_koonduslaager"}>
                  <img class="h-6 w-6" alt="" src="fb.png"></img>
                </button>
                </div>
                <button class="fade-in opacity-0 shadow-md stylesheet-bg-btn items-center rounded-full flex p-1 w-2/3 mt-6" onClick={() => window.location.href = "/enesetutvustus"}>
                  <h1 class="font-sec pl-4">Enesetutvustus</h1>
                  <h1 class="font-sec text-xl absolute right-4 font-bold">{">"}</h1>
                </button>
              </div>
              </div>
          </div>
        </div>
    )
}

export default Main;