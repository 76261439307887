import React, { useEffect } from "react";
import gsap from "gsap";
const Navbar = () => {

    useEffect(() => {
        gsap.fromTo(".logo-voco", {opacity:0,x:-20},{opacity:1,x:0})
        gsap.fromTo(".fade-anim", {opacity:0},{opacity:1})
    }, [])
    
   return(
    <div class="bg-g-4 h-12 items-center flex pl-6 pr-6">
            <h1 class="text-white text-xl font-title border-r pr-3 cursor-pointer fade-anim opacity-0" onClick={() => window.location.href = "/"}>PHOTOFORGE</h1>
            <img alt="" class="h-12 ml-2 logo-voco opacity-0" src={require('./voco it kool logod RGB-04.png')}></img>
            <div class="absolute right-6 flex">
              <button class="p-2 stylesheet-anim-button-hover-navbar fade-anim opacity-0"><h1 class="text-white font-sec text-sm" onClick={() => window.location.href = "/enesetutvustus"}>Enesetutvustus</h1></button>
              <div class="p-1">{}</div>
              <button class="p-2 stylesheet-anim-button-hover-navbar fade-anim opacity-0"><h1 class="text-white font-sec text-sm" onClick={() => window.location.href = "/opingud"}>Õpingud</h1></button>
              <div class="p-1">{}</div>
              <button class="p-2 stylesheet-anim-button-hover-navbar fade-anim opacity-0"><h1 class="text-white font-sec text-sm" onClick={() => window.location.href = "/browse"}>Fotograafia</h1></button>
              <div class="p-1">{}</div>
              <button class="p-2 stylesheet-anim-button-hover-navbar fade-anim opacity-0"><h1 class="text-white font-sec text-sm" onClick={() => window.location.href = "/kontakt"}>Kontakt</h1></button>
        </div>
    </div>
   )
}

export default Navbar;