import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

//TAILWINDCSS + CUSTOM
import './index.css';

//JSX FILES TO ROUTE
import Main from './container/Main';
import Opingud from './container/opingud';
import Enesetutvustus from './container/enesetutvustus';
import Browse from './container/browse';
import Kontakt from './container/kontakt';
import NotFound from './404';

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path='/*' element={<NotFound />} />

      <Route path='/' element={<Main />} />
      <Route path='/opingud' element={<Opingud />} />
      <Route path='/enesetutvustus' element={<Enesetutvustus />} />
      <Route path='/kontakt' element={<Kontakt />} />
      <Route path='/browse' element={<Browse />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root'),
);

