import React, { useEffect } from "react";
import Navbar from "./components/navbar";
import Background from "./components/background";
import gsap from "gsap";
const NotFound = () => {

    const HandleClick = () => {
        gsap.to(".auto", {left:0, opacity:0, transitionDuration:1.5})

        setTimeout(() => {
            window.location.href = "/"
        }, 1600);
    }

    useEffect(() => {
        gsap.to(".fade-in", {opacity:1})
    
        setTimeout(() => {
            gsap.to(".fade-in-t", {opacity:1})
        }, 200);
    },[])
    

    return (
        <div class="overflow-hidden h-screen">
          <Navbar></Navbar>
    
    
          <Background ></Background>
          <div class="absolute w-full stylesheet-h flex items-center justify-center">
            <div class="w-2/3">
              <h1 class="fade-in opacity-0 text-9xl text-white font-bold text-center">404</h1>
              <h1 class="fade-in-t opacity-0 text-4xl text-white font-sec text-center">Piim on otsas</h1>
              <button class="fade-in-t opacity-0 shadow-md stylesheet-bg-btn items-center rounded-full flex p-1 w-full mt-6" onClick={() => HandleClick()}>
                <h1 class="font-sec text-center w-full">Trip to piimalett</h1>
                <h1 class="auto font-sec text-xl absolute right-4 font-bold">{"🚗💨"}</h1>
            </button>
            </div>
          </div>
        </div>
      )
}

export default NotFound;